import './App.less';

import {BackTop, Collapse, Layout, Menu, Typography, Avatar} from 'antd';
import {Route, Redirect, useHistory,useLocation} from 'react-router-dom'
import React, {useContext, useEffect, useState} from "react";
import Tests from "./pages/Tests";
import {UserContext} from "@root/provider/UserProvider";
import Localizator from "@global/Localizator";
import {BarsOutlined, LoginOutlined, LogoutOutlined, RiseOutlined} from '@ant-design/icons';
import {getAllAnswers, signInWithFacebook, signInWithGoogle, signOut} from "@root/firebase";
import TestsProgress from "@pages/TestsProgress";

const {Header, Content, Footer, Sider} = Layout;
const {Title, Paragraph, Text} = Typography;
const {Panel} = Collapse;

function App() {
    const user = useContext(UserContext);
    let history = useHistory()
    let location = useLocation()
    const [selectedKey, setSelectedKey] = useState('/testing')

    useEffect(() => {
        return history.listen((location) => {
            console.log(`You changed the page to: ${location.pathname}`)
            setSelectedKey(location.pathname)
        })
    },[history])

    const handleClick = e => {
        console.log('click ', e);
        if (e.key === 'signOut') {
            signOut().then(() => {
                history.push('/')
            })
            return
        }
        history.push(`${e.key}`);
    };

    const NoUserHeader = () => {
        return <Header style={{background: 'transparent'}}>
            <Menu selectedKeys={[]} mode="horizontal" style={{background: 'transparent'}}>
                <Menu.Item key="google" icon={<LoginOutlined/>} style={{float: 'right'}} onClick={signInWithGoogle}>
                    {Localizator.t("menu.signIn.google")}
                </Menu.Item>
                <Menu.Item key="facebook" icon={<LoginOutlined/>} style={{float: 'right'}} onClick={signInWithFacebook}>
                    {Localizator.t("menu.signIn.facebook")}
                </Menu.Item>
            </Menu>
        </Header>
    }

    const Hello = ( ) => {
        if (user?.uid) {
            return <Text>Hello, user.displayName</Text>
        } else {
            return null
        }
    }

    const UserHeader = () => {
        return <Header style={{background: 'transparent'}}>

            <Menu selectedKeys={[selectedKey]} mode="horizontal" onClick={handleClick}
                  style={{background: 'transparent'}}>
                <Menu.Item key="/testing" icon={<BarsOutlined/>}>
                    {Localizator.t("menu.tests")}
                </Menu.Item>
                <Menu.Item key="/dynamics" icon={<RiseOutlined/>}>
                    {Localizator.t("menu.dynamics")}
                </Menu.Item>
                <Menu.Item key="signOut" icon={<Avatar src={user.photoURL}></Avatar>} style={{float: 'right'}}>

                    {Localizator.t("menu.signOut")}
                </Menu.Item>
            </Menu>
        </Header>
    }

    const UserMenu = () => {
        if (user?.uid) {
            return UserHeader()
        } else {
            return NoUserHeader()
        }
    }
    return (
        <Layout className="layout">
            <BackTop key="backtop"/>
            <UserMenu/>
            <br/><br/>
            <Route exact path={["/", "/testing"]} component={() => <Tests/>}/>
            <Route exact path={"/dynamics"} component={() => <TestsProgress/>}/>
        </Layout>
    );
}

export default App;
