import {Col, Collapse, Layout, Row, Typography} from 'antd';
import React, {useEffect, useState} from "react";
import OneTest from "@components/OneTest";
import Localizator from "@global/Localizator";
import { getAllAnswers} from "@root/firebase";
import { Line } from '@antv/g2plot';

const {Header, Content, Footer} = Layout;
const {Title, Paragraph, Link, Text} = Typography;


const {Panel} = Collapse;

const TestsProgress = () => {

    useEffect(() => {
        getAllAnswers().then((data) => {
                if (!data) {
                    return
                }
                let chartData = []
                for (let dateResult of data) {
                    for (let chartResult of dateResult.results) {
                        console.log(chartResult)
                        if (chartResult.result && chartResult.result.value) {
                            chartData.push({
                                date: dateResult.date,
                                value: chartResult.result?.value,
                                key: chartResult.title
                            })
                        }
                    }
                }
                const linePlot = new Line('line-chart', {
                    data: chartData,
                    xField: 'date',
                    yField: 'value',
                    seriesField: 'key',
                    legend: {
                        position: 'top',
                    },
                    smooth: true,
                    animation: {
                        appear: {
                            animation: 'path-in',
                            duration: 5000,
                        },
                    },
                });

                linePlot.render();
            });
    }, []);

    return (
        <Content style={{padding: '0 13px'}}>
            <div className="site-layout-content">
                <Title level={3}>{Localizator.t('title.header.dynamics')}</Title>
                <Text underline>М.А. Тетюшкин</Text>
                <i/>
                <div id={'line-chart'} style={{height: 300}}/>
            </div>
        </Content>
    );
}

export default TestsProgress;
