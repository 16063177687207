/* eslint-disable react/prop-types */
import {Typography, Radio, Button, Card, Col, Input, Row, Select} from 'antd';
import * as React from "react";
const { Title } = Typography;

const {Meta} = Card;

const {Option} = Select;

// eslint-disable-next-line react/prop-types
const TestQuestion = ({question, options, handleChange}) => {
    return (
        <div style={{padding: 13}}>
            <Title level={5}>{question.text}</Title>
            <Radio.Group onChange={(e) => handleChange(question.index, e)} value={question.result} buttonStyle="solid">
                {options.map(op => {
                    return <Radio key={op.text} value={op.value}>{op.text}</Radio>
                })}
            </Radio.Group>
        </div>
    );
}

export default TestQuestion
