import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
const RESULTS_COLLECTION_NAME = 'user_results'

const getTimeEpoch = () => {
    return new Date().getTime().toString();
}

function addDays(days) {
    return new Date(Date.now() + 864e5 * days);
}

export const firebaseConfig = {
    apiKey: "AIzaSyC_lL_VE4HMXYfezio-kU_e70wo94qLMpE",
    authDomain: "mednauka-net.firebaseapp.com",
    databaseURL: "https://mednauka-net.firebaseio.com",
    projectId: "mednauka-net",
    storageBucket: "mednauka-net.appspot.com",
    messagingSenderId: "54630211390",
    appId: "1:54630211390:web:75bab2408246fdd756566f"
};

export const fbInstance = firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = 'ru_RU';
export const AUTH = firebase.auth();
const db = firebase.firestore();

export const signInWithGoogle = () => {
    AUTH.signInWithPopup(new firebase.auth.GoogleAuthProvider());
};

export const signInWithFacebook = () => {
    AUTH.signInWithPopup(new firebase.auth.FacebookAuthProvider());
};

export const signOut = async () => {
    AUTH.signOut().then(() => {
        console.log('signet out')
    })
}

export const getAllAnswers = async ( ) => {
    if (!AUTH.currentUser) {
        return
    }
    return db.collection("user_results").where("user", "==", AUTH.currentUser.uid).get()
        .then(snapshot => snapshot.docs.map(x => x.data()))
}

export const getAnswersForDate = async (date) => {
    if (!AUTH.currentUser) {
        return null
    }
    console.log(date, AUTH.currentUser.uid)
    return db.collection("user_results").where("user", "==", AUTH.currentUser.uid).
        where("date", "==", date) .get()
        .then(snapshot => {
            if (!snapshot.empty) {
                return snapshot.docs[0].data()
            } else {
                console.log('no data for today yet')
                return null
            }
        })
}



export const saveAnswers = async (testResults) => {
    if (!AUTH.currentUser) {
        return
    }
    const date = new Date().toLocaleDateString('en-CA');
    const id = AUTH.currentUser.uid + '_' + date
    let resultsToSave = {
        results: testResults
    }
    resultsToSave.id = id
    resultsToSave.date = date
    resultsToSave.user = AUTH.currentUser.uid

    await db.collection(RESULTS_COLLECTION_NAME).doc(id).set(resultsToSave)
}
