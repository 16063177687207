import React, { Component, createContext } from "react";
import { AUTH } from "@root/firebase";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
    state = {
        user: null
    };

    componentDidMount = () => {
        AUTH.onAuthStateChanged(userAuth => {
            if (userAuth)  {
                const user = {
                    uid: userAuth.uid,
                    email: userAuth.email,
                    emailVerified: userAuth.emailVerified ? true : false,
                    photoURL: userAuth.photoURL,
                    displayName: userAuth.displayName
                }
                console.log(user)
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
    };
    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider;
