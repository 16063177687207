import {message, Col, Collapse, Layout, Row, Typography} from 'antd';
import React, {useEffect, useState} from "react";
import OneTest from "@components/OneTest";
import Localizator from "@global/Localizator";
import {saveAnswers, getAnswersForDate} from "@root/firebase";

const {Header, Content, Footer} = Layout;
const {Title, Paragraph, Link, Text} = Typography;


const {Panel} = Collapse;

function Tests() {
    const [testResults, setTestResults] = useState([{
        key: 'ps_zil',
        title: 'Шкала PS ЗИЛ',
        result: {value: null, text: 'Пройдите тест'}
    }, {
        key: 'gtt',
        title: 'Госпитальная шкала тревоги',
        result: {value: null, text: 'Пройдите тест'}
    }, {
        key: 'gtd',
        title: 'Госпитальная шкала депрессии',
        result: {value: null, text: 'Пройдите тест'}
    }, {
        key: 'as',
        title: 'Шкала астенического состояния',
        result: {value: null, text: 'Пройдите тест'}
    }, {
        key: 'os',
        title: 'Общее состояние',
        result: {value: null, text: 'Пройдите тест'}
    }, {
        key: 'pz',
        title: 'Психическая зависимость',
        result: {value: null, text: 'Пройдите тест'}
    }])

    function callback(key) {
        console.log(key)
    }

    useEffect(()=> {
        const date = new Date().toLocaleDateString('en-CA');
        getAnswersForDate(date).then((result) => {
            if (result) {
                console.log(result);
                setTestResults(result.results)
            }
        })
    }, [])

    const setResult = ({value, text, type, key}) => {
        console.log(value, text, key, type)

        let index = testResults.findIndex(test => test.key == key)
        let copy = [...testResults]
        copy[index].result = {
            value: value,
            text: text,
            type: type,
        }
        setTestResults(copy)
        if (type == 'danger') {
            message.error(`${value}, ${text}`);
        } else if (type == 'warning') {
            message.warn(`${value}, ${text}`);
        } else {
            message.success(`${value}, ${text}`);
        }

        saveAnswers(copy).then(() => {
            console.log('results saved')
        }).catch(e => {
            console.log('error', e)
        })
    }

    const CollapseHeader = (test) => {
        if (test.result.value && test.result.type) {
            if (['danger', 'warning'].includes(test.result.type)) {
                return <Row>
                    <Col span={12}>
                        {test.title}
                    </Col>
                    <Col span={12}>
                        <Text>{test.result.value}, </Text>
                        <Text mark type={test.result.type}>{test.result.text}</Text>
                    </Col>
                </Row>
            } else {
                return <Row>
                    <Col span={12}>
                        {test.title}
                    </Col>
                    <Col span={12}>
                        <Text>{test.result.value}, </Text>
                        <Text type={test.result.type}>{test.result.text}</Text>
                    </Col>
                </Row>
            }

        } else {
            return <Row>
                <Col span={12}>
                    {test.title}
                </Col>
                <Col span={12}>
                    <Text type='secondary'>{test.result.text}</Text>
                </Col>
            </Row>
        }

    }
    return (
        <Content style={{padding: '0 13px'}}>
            <div className="site-layout-content">
                <Title level={3}>{Localizator.t('title.header.grads')}</Title>
                <Text underline>М.А. Тетюшкин</Text>
                <i/>
                <Title level={5}>Как пользоваться шкалами самооценки?</Title>
                <Paragraph>Выполнить, записать результат &mdash; сделать сейчас и
                    только потом идти дальше. Для получения более подробной информации, смотрите&nbsp;
                    <Link href="http://www.youtube.com/watch?v=hPbFjCrrQL8" target="_blank">
                        видео
                    </Link> на канале <Link target="_blank"
                                            href="https://www.youtube.com/channel/UCUs4YGaEXLUtCzshe-PhbCw">mednauka.net </Link> (ссылки
                    откроются в новом окне).
                </Paragraph>

                <Collapse onChange={callback} defaultActiveKey={[]}>
                    {testResults.map(test => {
                        return <Panel header={CollapseHeader(test)} key={test.key} collapsible={test.collapsible}>
                            <OneTest testName={test.key} pushResult={setResult}/>
                        </Panel>
                    })
                    }
                </Collapse>
                <br/>
                <Title level={5}>А о чем, собственно, могут свидетельствовать наши результаты?</Title>
                <Paragraph>Как я уже писал - о чем угодно. Они могут говорить о том, что человек здоров и просто имеет
                    меланхолический темперамент. Та же сумма балов может свидетельствовать и о наличии депрессивного
                    синдрома. Одинаковые баллы в шкале астении могут быть и при усталости, и при начальной стадии
                    шизофрении. Поэтому не спешите делать скоропалительные выводы и тем более начинать принимать
                    какие-то лекарства, а ознакомьтесь со всей предложенной информацией и при необходимости задайте
                    вопросы.</Paragraph>
                <Title level={5}>Телефоны для записи на консультацию</Title>
                <a href='tel:+74991133423'>+7 (499) 113-34-23</a><br/>
                <br/>
                <Title level={5}>email</Title>
                <a href='mailto: mednauka@yahoo.com'>mednauka@yahoo.com</a>
            </div>
        </Content>
    );
}

export default Tests;
