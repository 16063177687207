import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import UserProvider from "@root/provider/UserProvider";
import {BrowserRouter} from "react-router-dom";
//const auth = new Auth();
ReactDOM.render(
    <ConfigProvider locale={ruRU}>
        <UserProvider>
            <BrowserRouter basename={'/psycho/'}>
                <App/>
            </BrowserRouter>
        </UserProvider>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
