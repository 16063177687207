
export default class Localizator {

    static labelMap;

    static t(label) {
        const ru = require('@root/locs/ru.json')
        if (ru[label]) {
            return ru[label]
        } else {
            return label
        }

    }
}
