import React, {useEffect, useState} from 'react';
import TestQuestion from "./TestQuestion";
import {Card, Typography} from "antd";

const {Meta} = Card;
const {Title, Paragraph, Link, Text} = Typography;


const OneTest = ({
                     testName = 'ps_zil', pushResult = () => {
    }
                 }) => {
    const [localQuestions, setLocalQuestions] = useState([]);
    const [currentTest, setCurrentTest] = useState({});

    useEffect(() => {
        const test = require(`../data/${testName}.json`)
        setCurrentTest(test)
        setLocalQuestions([...test.questions])
    }, []);

    const Questions = () => {
        return <div>
            {localQuestions.map(q => {
                return <TestQuestion key={q.index} options={currentTest.defaultAnswers} question={q}
                                     handleChange={handleAnswer}/>
            })
            }
        </div>
    }

    const handleAnswer = (index, e) => {
        console.log(index, e.target.value)
        let answer = localQuestions[index]
        answer.result = e.target.value
        let copy = [...localQuestions]
        copy[index] = answer
        setLocalQuestions(copy)
        checkReady(copy)
    }

    const checkReady = (questions) => {
        let sum = 0
        for (let q of questions) {
            if (q.result == null) {
                return
            }
            sum += q.result
        }
        const variants = currentTest.results
        let ourVariant = null
        for (let v of variants) {
            ourVariant = v
            if (sum < v.max) {
                break
            }
        }
        pushResult({value: sum, text: ourVariant.text, key: testName, type: ourVariant.type})
    }

    return (
        <Card
            style={{width: '100%'}}
        >
            <Meta
                title={currentTest.title}
                description={currentTest.description}
            />
            <Text underline>{currentTest.author}</Text>
            <Paragraph style={{marginTop: 39}}>
                {currentTest.sub}
            </Paragraph>
            <Questions/>
        </Card>);
}

export default OneTest
